<template>
<my-slot >
		<div class="my_index_c"  slot="content">
			<h3>{{this.$t('mailbox')}}</h3>
			<div>
				<ul>
					<li>
						<p>{{this.$t('newEmail')}}:</p>
						<div class="my_index_rt">
							<div class="my_index_rt_2"><input v-model="getInfo.userEmail" type="text" :placeholder="this.$t('iptNewEmail')" /></div>
						</div>
						<div class="clear"></div>
					</li>
					<li>
						<p>{{this.$t('testCode')}}:</p>
						<div class="my_index_rt">
							<div class="my_index_rt_2"><input v-model="getInfo.code" type="text" :placeholder="this.$t('inputCode')" />
							<button @click="getCode" v-if="show">{{this.$t('getCode')}}</button>
							<button v-else disabled class="return-code">{{count}}秒后重新获取</button></div>
						</div>
						<div class="clear"></div>
					</li>
					<li><div class="my_index_rt_4"><input name="" type="button" :value="this.$t('sureChange')" @click="confirmChange"/></div></li>
				</ul>
			</div>
		</div>
</my-slot>
 
</template>

<script>
import MySlot from '@/components/component/MySlot.vue'
import { showLayer } from "@/utils/com";
import { getEmailCode,updataEmail } from './model' 
export default {
	data(){
		return {
			getInfo:{
				userEmail:'',
				code:'',
				type:1
			},
			disable:true,
			show:true,
			count:''
		}
	},
	components:{
		MySlot
	},
	created(){
		
	},
	computed:{
	},
	methods:{
		// 获取验证码
		getCode(){
			if(this.getInfo.userEmail === ''){
				showLayer("toast", "请输入新的邮箱！");
			}else {
				getEmailCode(this.getInfo).then(res =>{
				if(res === 'success') {
					showLayer("success", "验证码获取成功！");
					this.show = false;
						const TIME_COUNT = 60;
						if (!this.timer) {
						this.count = TIME_COUNT;
						this.show = false;
						this.timer = setInterval(() => {
						if (this.count > 0 && this.count <= TIME_COUNT) {
							this.count--;
						} else {
							this.show = true;
							clearInterval(this.timer);
							this.timer = null;
						}
					}, 1000)
				}
				}
			})
			}
		},
		//修改邮箱
		confirmChange(){
			if(this.getInfo.code === ''){
				showLayer("toast", "请输入验证码！");
			}else {
				updataEmail(this.getInfo).then(res=>{
					if(res === '操作成功！'){
						showLayer("success", "邮箱修改成功");
					}
				})
			}
		}	
	}
	
}
</script>

<style>
	.return-code {
		margin-left: -15px;
	}
</style>