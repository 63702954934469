<template>
  <div>
    <search/>
  <div class="my-slot">
    <div class="w ww my_index">
      <!-- <div class="bread">
        {{this.$t('position')}} > <a>{{this.$t('aSetting')}}</a> > <a>{{this.$t('data')}}</a>
      </div> -->
      <div class="pr">
        <div class="my_index_lf">
          <!-- <div><img :src="pictrueUrl+userPictrue" width="100%" /></div> -->
          <div class="my_index_lf_1">
            <p>{{this.$t('aSetting')}}</p>
            <ul>
              <li><a  v-for="(item,index) in LoginAndRegister" :key="index" :class="{'actived':curPath == item.url}" @click="goLoginAndRegister(item.url)">{{item.name}}</a></li>
            </ul>
          </div>
          <div class="my_index_lf_2"><a  @click="signOut">{{this.$t('exitLogin')}}</a></div>
        </div>
        <slot name="content"></slot>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import Search from "./Search.vue";
import { showLayer } from "@/utils/com";
import { IMGURL } from "@/utils/constant";
export default {
  data(){
    return {
      pictrueUrl:'',
      userPictrue:''
    }
  },
  components: {
    Search
  },
  created(){
    this.pictrueUrl = IMGURL;
    // this.userPictrue = window.localStorage.getItem('userPictrue')
  },
  computed:{
     LoginAndRegister () {
      return [
        {name:this.$t('data'),url: "/personal" },
        {name:this.$t('Harvest'),url: "/address"},
        {name:this.$t('changeP'),url: "/changePassword" },
        {name:this.$t('changePhone'),url: "/changePhone" },
        {name:this.$t('mailboxs'),url: "/changeEmail" }
      ]
    },
     curPath(){
      return this.$route.path
    }
  },
  methods:{
    signOut(){
      window.localStorage.clear('sessionId');
      this.$router.push('/login')
       showLayer("success", "退出成功！");
    },
      goLoginAndRegister(path){
      this.$router.push(path)
    },
  }
};
</script>

<style scoped>
.actived {
  color: #ef535d;
}
</style>