import { Control } from '@/api/index'

export async function getEmailCode(data) {
    let res = await Control.getEmailCode(data)
    return res;
}

export async function updataEmail(data) {
    let res = await Control.updataEmail(data)
    return res;
}